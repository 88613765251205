.header-block {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  outline: none !important;

  * {
    outline: none !important;
  }

  &.stick {
    position: fixed;
  }

  .header-top-block {
    background: #000;
    padding: 20px 0 30px;

    .header-top {
      .phone-link {
        font: 400 18px "Intro-Bold", sans-serif;
        color: rgba(255, 255, 255, 0.5);
        transition: all .2s linear;
        text-decoration: none !important;

        &:hover {
          color: rgba(255, 255, 255, 1);
        }

        @media (max-width: 575px) {
          font-size: 16px;
        }

        @media (max-width: 340px) {
          font-size: 15px;
        }
      }

      .address-link {
        font: 300 18px/1.2 "Roboto", sans-serif;
        color: #8C9ABE;
        transition: all .2s linear;
        text-decoration: underline !important;

        &:hover {
          color: #FFF;
          text-decoration-color: transparent !important;
        }

        @media (max-width: 575px) {
          font-size: 14px;
        }

        @media (max-width: 340px) {
          font-size: 13px;
        }
      }
    }

    @media (max-width: 800px) {
      padding: 16px 0 20px;
    }

    @media (max-width: 500px) {
      padding: 2px 0 4px;
    }
  }

  .nav-block {
    background: #000;

    .head-logo {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;

      img, svg {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
      }

      @media (max-width: 700px) {
        max-width: 134px;
      }

      @media (max-width: 600px) {
        max-width: 134px;
      }

      @media (max-width: 400px) {
        max-width: 110px;
        margin-right: 5px;
      }
    }

    .navbar-box {
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;

      .btn-menu {
        @media (max-width: 991px) {
          position: relative;
          float: right;
          top: 38px;
          margin-top: 0;
          margin-right: 16px;
        }

        @media (max-width: 767px) {
          top: 103px;
        }

        @media (max-width: 700px) {
          top: 87px;
        }

        @media (max-width: 600px) {
          top: 87px;
        }

        @media (max-width: 575px) {
          top: 82px;
        }

        @media (max-width: 500px) {
          top: 51px;
        }
      }

      .navbar-nav {

        li {
          &.menu-rss-item {
            margin:10px 0;
            ul {
              display: flex;
              justify-content: center;
            }
          }

          .my-nav-menu-item {
            font: 300 16px/1.2 "Roboto", sans-serif;
            color: #95A4C9 !important;
            text-transform: uppercase;
            transition: color .2s linear;

            &:hover {
              color: #FFF !important;
            }

            @media (max-width: 1200px) {
              font-size: 14px;
            }

            @media (max-width: 991px) {
              font-size: 18px;
            }
          }
        }

        @media (max-width: 991px) {
          padding: 92px 52px 0;
          height: 100%;
          overflow: auto;
          width: 100%;
          clear: both;
          float: none;
        }
      }

      @media (max-width: 991px) {
        width: 100%;
        height: 100%;
        box-sizing: content-box;
        max-width: 280px;
        top: 0;
        right: 0;
        padding-bottom: 80px;
        position: fixed;
        left: auto;
        background: #020203;
        z-index: 1000 !important;
        cursor: auto;
        transition: all .2s linear;
        visibility: hidden;
        opacity: 0;
      }
    }

    .nav_side {
      .head-options-dropdown {
        margin-left: 10px;

        .my-dropdown {
          min-width: 160px;
          max-width: 220px;
        }

        @media (max-width: 1428px) {
          position: absolute;
          top: 100%;
          right: 0;
          margin: 10px auto auto;
          width: auto;
        }

        @media (max-width: 991px) {
          margin: 0 0 0 10px;
          position: static;
        }

        @media (max-width: 460px) {
          display: none;
        }
      }

      @media (max-width: 400px) {
        .btn-reg-auth {
          a {
            font-size: 13px;
          }

          > span {
            font-size: 14px;
            padding: 0 3px;
          }
        }
      }
    }

    &.nav-extended .nav_side {
      margin-right: -112px;

      @media (max-width: 1428px) {
        margin-right: 0;
        position: relative;
      }
    }

    .btn-menu {
      cursor: pointer;
      display: block;
      width: 32px;
      height: 24px;
      position: relative;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      outline: none !important;

      &:after, &:before, span {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 4px;
        background: #879BC0;
        box-sizing: border-box;
        transition: all .2s linear;
        -webkit-transform: rotate(0deg) translateX(0);
        transform: rotate(0deg) translateX(0);
        opacity: 1;
        -webkit-pointer-events: none;
        pointer-events: none;
      }

      &:before {
        left: 0;
        top: 0;
      }

      &:after {
        left: 0;
        bottom: 0;
      }

      span {
        left: 0;
        top: 10px;
      }

      &:hover {
        &:after, &:before, span {
          background: #FFF;
        }
      }
    }

    .btn-reg-auth {
      white-space: nowrap;
      flex: 0 0 auto;
      -ms-flex: 0 0 auto;

      a {
        line-height: 1px;
        color: #8C9ABE;
        text-decoration: none !important;
        transition: all .2s linear;
        font: 300 16px/16px "Roboto", sans-serif;
        border-bottom: 1px solid #8C9ABE;

        &:hover {
          border-color: transparent;
        }
      }

      > span {
        padding: 0 5px;
        color: #FFF;
        font: 500 18px/18px "Roboto", sans-serif;
      }
    }

    .btn-dropdown {
      display: block;
      min-width: 188px;
      border: solid 2px #68090b;
      cursor: pointer;
      line-height: 36px;
      min-height: 40px;
      text-decoration: none !important;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-size: 0;
      font-weight: normal;
      padding: 0 5px;
      border-radius: 5px;
      text-align: left;

      .btn_text {
        position: relative;
        vertical-align: middle;
        font: 16px/18px "Intro-Bold", sans-serif;
        color: #FFF;
        white-space: normal;
        padding-right: 14px;
        padding-top: 5px;
        padding-bottom: 5px;
        width: 100%;
        display: inline-block;

        &:after {
          content: '';
          display: block;
          position: absolute;
          box-sizing: border-box;
          border-bottom: 4px solid #FFF;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          right: 0;
          top: 50%;
          margin-top: -2px;
          -webkit-transform: scale(1, -1);
          transform: scale(1, -1);

          @media (max-width: 700px) {
            top: 50%;
          }
        }
      }
    }

    .dropdown.show .btn-dropdown .btn_text:after {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }

    .my-dropdown {
      .btn-dropdown {
        background: #020203;
      }

      .dropdown-menu {
        border: solid 1px #68090B;
        background-color: #000;
        border-radius: 0;
        padding: 1px;
      }

      .dropdown-item {
        font: 400 14px/16px "Intro-Bold", sans-serif;
        color: #F1F2F6;
        transition: all .2s linear;
        background: transparent;
        padding: 8px 14px;

        &:hover {
          background: #68090B;
          color: #FFF;
        }

        &.disabled {
          color: #8D8E96;
          pointer-events: none;
          -webkit-pointer-events: none;
        }
      }

      .dropdown-menu {
        width: 100%;
      }

      .dropdown-menu-divider {
        width: 100%;
        padding: 0 14px;
        margin: 8px 0;

        &:after {
          content: '';
          display: block;
          background: #FFF;
          opacity: .35;
          height: 1px;
          width: 100%;
        }
      }
    }

    .phone-link {
      font: normal 18px/1.2 "Intro-Bold", sans-serif;
      color: #FFF;
      text-decoration: none;

      &:hover {
        color: #FFF;
        text-decoration: none;
      }

      @media (max-width: 1200px) {
        font-size: 24px;
      }

      @media (max-width: 1030px) {
        font-size: 21px;
      }

      @media (max-width: 991px) {
        font-size: 24px;
      }
    }

    .head-options-container {
      display: none;

      @media (max-width: 460px) {
        display: block;
        margin-top: 10px;

        .my-dropdown .btn-dropdown {
          text-align: left;
        }
      }
    }
  }

  @media (max-width: 800px) {
    position: relative !important;
  }

  @media (max-width: 460px) {
    padding-bottom: 15px;
  }
}

@media (max-width: 991px) {
  body {
    &:after {
      content: '';
      display: block;
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 99 !important;
      box-sizing: content-box;
      background: rgba(2, 2, 3, 0.75);
      left: 0;
      top: 0;
      opacity: 0;
      visibility: hidden;
      padding-bottom: 80px;
      transition: all .2s linear;
    }

    &.menu_active {
      overflow: hidden;

      &:after {
        opacity: 1;
        visibility: visible;
      }

      .header-block {

        .navbar-box {
          visibility: visible;
          opacity: 1;
        }

        .btn-menu {
          &:before {
            top: 10px;
            -webkit-transform: rotate(45deg) translateX(0);
            transform: rotate(45deg) translateX(0);
          }

          &:after {
            bottom: 10px;
            -webkit-transform: rotate(-45deg) translateX(0);
            transform: rotate(-45deg) translateX(0);
          }

          span {
            top: 10px;
            -webkit-transform: rotate(0deg) translateX(-20%);
            transform: rotate(0deg) translateX(-20%);
            opacity: 0;
          }
        }
      }
    }
  }
}